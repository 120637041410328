<template>
  <access-control :lendflow="true" :clients="true">
    <test-mode-modal v-if="showModal" :close="() => (showModal = false)" />
    <sidebar-button
      v-if="props.testMode"
      :sidebarExpanded="sidebarExpanded"
      class="relative"
      :tooltipLabel="$t('ORGANIZATION.TEST_MODE.TITLE')"
      insetButton
      insetColor="bg-secondary-purple hover:opacity-95"
      @click.prevent="showModal = true"
    >
      <template v-slot:icon>
        <div class="w-10 h-10 flex justify-center">
          <icon-base
            class="place-self-center"
            height="24"
            width="24"
            viewBox="0 0 20 20"
            icon="sandbox-white"
            iconColor="white"
            opacity="1"
          />
        </div>
      </template>
      <template v-slot:label>
        <div class="flex flex-col justify-between items-start">
          <span class="text-xs font-semibold">
            {{ $t("ORGANIZATION.TEST_MODE.TITLE") }}
          </span>
          <span
            class="text-xxs uppercase font-bold px-1 bg-secondary-green rounded-sm"
          >
            Go Live
          </span>
        </div>
      </template>
    </sidebar-button>
  </access-control>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import TestModeModal from "@/components/sidebar/TestModeModal.vue";
import SidebarButton from "@/components/sidebar/SidebarButton.vue";
const props = defineProps({
  testMode: {
    type: Boolean,
    default: false
  }
});

const { getters } = useStore();
const settings = computed(() => getters["options/settings"]);
const sidebarExpanded = ref<boolean>(
  settings.value.dashboardMenuExpanded ?? true
);
const showModal = ref(false);
</script>
